import axios from 'axios';

const identity = axios.create({
    baseURL: process.env.VUE_APP_BASE_IDENTITY_URL,
});

identity.defaults.headers.post['Content-Type'] = 'application/json';
identity.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

identity.defaults.method = "post";

export default identity;