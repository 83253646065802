<template>

  <div class="wrapper">

    <div class="content">

      <section v-show="action_login">

        <div>
          Enter your phone number and password below to login to your existing account. Otherwise click on Register with the
          same details to create a new account.
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Phone Number</label>
          <input type="tel" class="form-control input-login" name="mobile" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="e.g 0712 234567" v-model="msisdn">
        </div>

        <div class="form-group">

          <div style="display: flex;justify-content: space-between;">
            <label for="exampleInputPassword1">Password</label>
          </div>

          <div class="d-flex">
            <input :type="type" class="form-control input-login" name="password" id="exampleInputPassword1" v-model="password">
            <img :src="btnText" alt="password icon" style="position: absolute; right: 10px;top: 2.3em;" @click="showPassword">
          </div>
          <input type="hidden" name="utm_source" value="">
          <input type="hidden" name="utm_medium" value="">
          <input type="hidden" name="utm_campaign" value="">
          <input type="hidden" name="referrer" value="">

        </div>

        <div style="display: grid;grid-template-columns: 2fr 2fr; margin-left: 20px">

          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" value=""> Keep me logged in
            <span class="form-check-sign">
                <span class="check"></span>
              </span>
          </label>

          <span class="blue-txt bold-txt" @click="setGetResetCode">Forgot Password?</span>

        </div>

        <div style="display: grid;grid-template-columns: 3fr 1fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
                  @click="login">Login
          </button>

          <button
              @click="setSignup"
              style="border: 1px solid var(--black); background-color: var(--secondary); color: var(--black); font-weight: var(--font-weight-bold);"
              class="btn">Register
          </button>
        </div>

      </section>

      <section v-show="action_signup">

        <div>
          Enter your personal information below to register. Otherwise click on Login with the same details to access existing account.
        </div>

        <div class="form-group">
          <label for="msisdn">Phone Number</label>
          <input type="tel" class="form-control input-signup" name="mobile" id="msisdn" aria-describedby="emailHelp" placeholder="e.g 0712 234567" v-model="msisdn">
        </div>

        <div class="form-group">
          <label for="signupPassword">Password</label>
          <input type="password" class="form-control input-signup" name="password" id="signupPassword" v-model="password">
        </div>

        <div class="form-group">
          <label for="signupPassword1">Confirm Password</label>
          <input type="password" class="form-control input-signup" name="password_2" id="signupPassword1" v-model="password1">
        </div>

        <input type="hidden" name="utm_source" value="">
        <input type="hidden" name="utm_medium" value="">
        <input type="hidden" name="utm_campaign" value="">
        <input type="hidden" name="referrer" value="">

        <div style="display: grid;grid-template-columns: 3fr 1fr;">
          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading" @click="signup">Register</button>
          <button @click="setLogin" style="border: 1px solid var(--black); background-color: var(--secondary); color: var(--black); font-weight: var(--font-weight-bold);" class="btn">Login</button>
        </div>

      </section>

      <section v-show="action_reset_password">

        <div>
          Enter your phone Number to get a password reset code
        </div>

        <div class="form-group">
          <label for="msisdn1">Phone Number</label>
          <input type="tel" class="form-control" name="mobile" id="msisdn1" aria-describedby="emailHelp" placeholder="johndoe@demo.com" v-model="msisdn">
        </div>

        <div style="display: grid;grid-template-columns: 3fr 1fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
                  @click="getResetCode">Get Reset Code
          </button>

          <button
              @click="setLogin"
              style="border: 1px solid var(--black); background-color: var(--secondary); color: var(--black); font-weight: var(--font-weight-bold);"
              class="btn">Login
          </button>

        </div>

      </section>

      <section v-show="action_verify_password">

        <div>
          Enter the verification code sent via Phone to activate your account
        </div>

        <div class="form-group">
          <label for="code">Verification Code</label>
          <input type="number" class="form-control" name="mobile" id="code" aria-describedby="emailHelp" v-model="code">
        </div>

        <div style="display: grid;grid-template-columns: 4fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
                  @click="verifyAccount">Verify Account
          </button>

        </div>

      </section>

      <section v-show="action_change_password">

        <div>
          Password reset code has been send to your phone Number. Enter the code you have received in your phone number and your new password.
        </div>

        <div class="form-group">
          <label for="code1">Password Reset Code</label>
          <input type="number" class="form-control" name="mobile" id="code1" aria-describedby="mobileHelp" v-model="code">
        </div>

        <div class="form-group">
          <label for="signupPassword3">New Password</label>
          <input type="password" class="form-control" name="password" id="signupPassword3" v-model="password">
        </div>

        <div class="form-group">
          <label for="signupPassword4">Confirm Password</label>
          <input type="password" class="form-control" name="password_2" id="signupPassword4" v-model="password1">
        </div>

        <div style="display: grid;grid-template-columns: 4fr;">

          <button class="btn btn-primary" style="font-weight: var(--font-weight-bold);" v-bind:class="loading"
                  @click="changePassword">Change Password
          </button>

        </div>

      </section>

    </div>

    <BottomFooter></BottomFooter>

    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<style scoped>
  .input-signup, .input-login{
    border: 1px solid #ccc;
    border-radius: .5em;
    height: 3em;
  }
</style>

<script>
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";
import identity from "@/services/identity";

export default {
  name: 'Login',
  components: {
    BottomFooter,
    BottomNavigation
  },
  comments: {

  },
  data: function () {

    return {
      msisdn: '',
      password: '',
      password1: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      action_login: true,
      action_signup: false,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: '',
      placeBet: 0,
      type: 'password',
      btnText: 'assets/images/show.svg'
    }
  },
  methods: {

    login: function () {

      this.reset();
      this.removeAuth();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      identity.post(path, JSON.stringify({
        campaign: utm_campaign,
        login_tag: parseInt(login_tag),
        medium: utm_medium,
        msisdn: parseInt(this.msisdn),
        password: this.password,
        source: utm_source,
        referrer: JSON.stringify(referrer),
        useragent: JSON.stringify(navigator.userAgent),
      }))
          .then(res => {

            vm.loading = "";
            const profile = res.data;
            const status = parseInt(res.data.status);

            if (status === 201 || status === 204) {

              vm.setSuccess("Success", "If your phone number exists, you will receive a verification code");
              vm.setVerifyAccount();

            }
            else {

              vm.setSuccess("Success", "Login successful");
              vm.EventBus.$emit("init:mqtt");

              const auth = res.data.auth;
              const placeBet = parseInt(vm.setPlaceBet);

              vm.setAuth(auth);
              vm.setProfile(profile);

              if (parseInt(vm.placeBet) === 1 ) {

                vm.setValue("placeBet",0);
                vm.EventBus.$emit('event:betslip:show');

              } else if (placeBet === 2) {

                vm.setValue("placeBet", 0);
                vm.EventBus.$emit("event:outrightbetslip:show");

              } else {

                //vm.goBack();
                window.location.href = "/";

              }

              vm.setProfile(profile);
              vm.setAuth(auth);

            }

          })
          .catch(err => {

            vm.loading = "";
            console.error("Error Occurred>>>>>>", err);

            if (err.response) {

              vm.setError("Failed", err.response.data.error_message);

              if (parseInt(err.response.data.error_code) === 401) {

                vm.setVerifyAccount();
                return;

              }

            }
            else if (err.request) {

              console.error("Network Request Error>>>>>>", err.request);

            }
            else {

              console.error("Other Error>>>>>>", err);

            }

          })

    },

    signup: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/signup";

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      //var referrer = this.getValue("referrer");

      identity.post(path, JSON.stringify({
        campaign: String(utm_campaign),
        first_name: String(""),
        last_name: String(""),
        medium: String(utm_medium),
        msisdn: parseInt(this.msisdn),
        password: String(this.password),
        source: String(utm_source),
        useragent: JSON.stringify(navigator.userAgent),
      }))
          .then(res => {

            vm.loading = '';
            console.log(JSON.stringify(res));
            var msg = res.data.data;
            vm.setSuccess("Signup successful", msg);
            // go to password verification page
            vm.setVerifyAccount();

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.error_message);

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again");

            } else {
              vm.setError("Network Error", "Check your network connection and try again");
            }

          })

    },

    getResetCode: function () {

      this.reset();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/password/forgot";

      identity.patch(path, JSON.stringify({
        msisdn: parseInt(this.msisdn)
      }))
          .then(res => {

            vm.loading = "";

            console.log(JSON.stringify(res));

            vm.setSuccess("Success", "Password reset code has been send to your phone");

            // go to password reset page
            vm.setChangePassword();

          })
          .catch(error => {

            vm.loading = "";

            if (error.response) {

              // console.error("Error Response:", JSON.stringify(error.response.data.error_message));
              vm.setError("Failed", error.response.data.error_message);

            } else if (error.request) {

              vm.setError("Network Error", "Check your network connection and try again");

            } else {

              vm.setError("Network Error", "Check your network connection and try again");

            }

          })

    },

    changePassword:function () {

      this.reset();

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/password/reset";

      identity.patch(path, JSON.stringify({
        code: parseInt(this.code),
        msisdn: parseInt(this.msisdn),
        password: String(this.password),
      }))
          .then(res => {

            vm.loading = "";

            vm.setSuccess("Success", "Login successful");
            vm.EventBus.$emit("init:mqtt");

            const auth = res.data.auth;
            const profile = res.data;

            vm.setAuth(auth);
            vm.setProfile(profile);

            if (parseInt(vm.placeBet) === 1 ) {

              vm.setValue("placeBet",0);
              vm.EventBus.$emit('event:betslip:show');

            } else {

              //vm.goBack();
              window.location.href = "/";

            }

          })
          .catch(err => {

            vm.loading = "";

            if (err.response) {

              vm.setError("Failed", err.response.data.error_message);

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again");

            } else {

              vm.setError("Network Error", "Check your network connection and try again");

            }

          })

    },

    verifyAccount: function () {

      this.reset();

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      identity.post(path, JSON.stringify({
        code: parseInt(this.code),
        login_tag: parseInt(login_tag),
        msisdn: parseInt(this.msisdn),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
        useragent: JSON.stringify(navigator.userAgent)

      }))
          .then(res => {

            vm.loading = "";
            var profile = res.data;
            vm.EventBus.$emit("init:mqtt");
            const auth = res.data.auth;

            vm.setAuth(auth);

            vm.setProfile(profile);

            vm.setSuccess("Congratulations!!", "Your account has been verified successfully");

            vm.EventBus.$emit("init:mqtt");

            if (parseInt(vm.placeBet) === 1 ) {

              vm.setValue("placeBet",0);
              vm.EventBus.$emit('event:betslip:show');

            } else {

              vm.setSuccess("Success", "Login successful");
              window.location.href = "/";

            }

          })
          .catch(err => {

            vm.loading = '';

            console.error("Error Occurred:", err);

            if (err.response) {

              vm.setError("Failed", err.response.data.error_message);

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again");

            } else {

              vm.setError("Network Error", "Check your network connection and try again");

            }

          })

    },

    setSignup: function(){

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function(){

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function(){

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function(){

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setVerifyAccount: function(){

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
    showPassword: function () {
      if(this.type === 'password') {
        this.type = 'text'
        this.btnText = '/assets/images/hide.svg'
      } else {
        this.type = 'password'
        this.btnText = '/assets/images/show.svg'
      }
    }

  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","join");
    this.placeBet = this.getValue("placeBet");

  }
}
</script>